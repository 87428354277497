<template>
    <div
        v-loading="loading"
        element-loading-background="rgba(122, 122, 122, 0.1)"
        element-loading-text="Please wait"
        :element-loading-spinner="svg"
        element-loading-svg-view-box="-10, -10, 50, 50"
        style="z-index: 1"
    >
        <div class="card card-policy-assigned">
            <SingleLineFilter 
                is-first-dates
                show-second-line
                show-search-keyword
                keyword-place-holder="Campaign Name"
                show-reset
                :is-policy-sales-agent="route.name == 'marketing-report-agent'"
                :is-policy-sales-agent-multiple="false"
                policy-sales-agent-place-holder="Select Agent"
                show-second-export
                :link-type="route.name"
                :clearable-date="false"
            />

            <TableModels 
                :data="data"
                :data-headers="header"
                :show-table-total="false"
                :table-total="data.total"
                table-message=""
                :loading="tableLoad"
                :is-sr="false"
                :show-footer="false"
                :other-data="data"
            />
        </div>
    </div>
</template>

<script setup>
import SingleLineFilter from '@/views/NEW/Reports/Models/SingleLineFilter.vue'
import TableModels from '@/views/NEW/Reports/Models/TableModel.vue'
import { computed, onMounted, ref, watch } from 'vue'
import { dates, rFilters } from '@/store/composable/Reports'
import { paginationsDef } from '@/core/utils/DefaultData'
import moment from 'moment'
import { marketing_report_headers } from '@/core/utils/Tables'
import { marketingReportHeadersDontShow, marketingReportAgentHeadersDontShow } from '@/core/utils/Listing'
import { useListing } from '@/store/composable/Listing'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

const store = useStore()
const route = useRoute()
const loading = ref(false)
const tableLoad = ref(true)
const data = ref(paginationsDef)
const isAgentReport = ref(false)

onMounted(() => {
    resetFilters()
    if(route.name == 'marketing-report-agent') { 
        isAgentReport.value = true;
        tableLoad.value = false
        loading.value = false
    }
})

watch(() => rFilters.value, async(filter) => {
    await listing()
}, { deep: true })

async function listing() {

        window.scrollTo({ top: 0, behavior: 'smooth' })
        if(route.name == 'marketing-report-agent') {
                loading.value = true
                const response = await useListing(rFilters.value, route.name)
                loading.value = false
                tableLoad.value = false
                data.value = response.data.data.report
        } else {
            loading.value = true
            const response = await useListing(rFilters.value, route.name)
            loading.value = false
            tableLoad.value = false
            data.value = response.data.data.report
        }
        
}

const header = computed(() => {
    if(isAgentReport.value) return marketing_report_headers.filter(x => filter_headers(x, dontShowHeadersAgent.value))
    else return marketing_report_headers.filter(x => filter_headers(x, dontShowHeaders.value))
})

const dontShowHeaders = computed(() => {
    return marketingReportHeadersDontShow;
})

const dontShowHeadersAgent = computed(() => {
    return marketingReportAgentHeadersDontShow;
})

function filter_headers(item, filter) {
    for (const key of filter) {
        if (item.value == key) 
        return false;
    }
    
    return true
}

function resetFilters() {
    const start = moment().startOf('month').format('YYYY-MM-DD')
    const end = moment().endOf('month').format('YYYY-MM-DD')
    dates.value = [start, end]
    rFilters.value = {
        agent: "",
        end_date: end,
        export: false,
        keyword: "",
        start_date: start,
        report_type:route.name
    }
    
}

</script>
